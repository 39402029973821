.page-container {
    display: flex;
    width: 100%;
    height: 100vh;
    max-width: 100vw;
    flex-direction: column;
    overflow-y: scroll;
}

.content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100vw;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;
}

/* The switch - the box around the slider */
/* height / width = 0.5666 (we halved the original input) 17, 30*/
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
  }
  
/* Hide default HTML checkbox */
.switch input {
opacity: 0;
width: 0;
height: 0;
}

/* The slider */
.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #e1e1e1;
-webkit-transition: .4s;
transition: .4s;
}
/* height / width = 0.5666  (we halved the original values) 13, 13*/
.slider:before {
position: absolute;
content: "";
height: 13px;
width: 13px;
left: 2px;
bottom: 2px;
background-color: white;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider {
background-color: #000000;
}

input:focus + .slider {
box-shadow: 0 0 1px #676868;
}

/* height / width = 0.5666  (we halved the original values) 13, 13*/
input:checked + .slider:before {
-webkit-transform: translateX(13px);
-ms-transform: translateX(13px);
transform: translateX(13px);
}

/* Rounded sliders */
/* height / width = 0.5666  (we halved the original values) 13, 13*/
.slider.round {
border-radius: 17px;
}

.slider.round:before {
border-radius: 50%;
}

.navbarColor {
    background-color: white;
    transition: all 0.4s;
}

.navbarColor.darkMode {
    background-color: #000000;
}

.pageColor {
    background-color: white;
    transition: all 0.4s;
}

.pageColor.darkMode {
    background-color: #000000;
    transition: all 0.4s;
}

.textColor {
    color: black;
    transition: all 0.4s;
}

.textColor.darkMode {
    color: white;
    transition: all 0.4s;
}

.caroselControlButtonsWhite {
    color:black;
    background-color: white;
    border-color: white;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border-radius: 25%;
    margin-bottom: 3%;
    transition: all 0.1s;
}
.caroselControlButtonsWhite:active {
    color:black;
    background-color: lightgrey;
    border-color: white;
    box-shadow: none;
    border-radius: 25%;
    margin-bottom: 3%;
}

.caroselControlButtonsBlack {
    color:black;
    background-color: black;
    border-color: black;
    outline-color: black;
    outline-style: solid;
    border-radius: 25%;
    margin-bottom: 3%;
}

#HOME-Middle-Column {
    max-height: 100%;
}

#homeMiddleColContent {
    max-height: 100%;
    flex-grow: 1;
}

#ChatBox-PD {
    outline: 2px solid red;
    border-radius: 25px;
    padding: 25px;
}

#ChatBox-MessageContainer {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: 0px;
    margin-bottom: 5px;
    outline: 2px solid darkred;
    box-sizing: border-box;
}


#ChatBox-MessageContainer::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

#TB-Respond-btn {
    border-radius: 25px;
    width: 50px;
    height: 25px;
    color: red;
    background-color: red;
    border-color: darkred;
}

#TB-TextArea {
    width: 100%;
    height: 30px;
    max-height: 100%;
    border-radius: 10px;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid #ccc;
    overflow: hidden;
    resize: none;
}

.message {
    margin-bottom: 5px;
    border-radius: 25px;
    width: calc(100% - 10px)
}

#messageRow {
    border-radius: 25px;
}